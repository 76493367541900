import Actions from '../../RouteActions';
import * as authService from '../../services/authService';
import baseService from '../../services/baseService';
import { getDeviceFingerprint } from '../../AsyncStorage';
import * as authActionConstants from '../../constants/actions/auth';
import {
    SET_SPINNER_VISIBLE,
    SET_SPINNER_HIDDEN
} from '../../constants/actions/appActions';
import * as stepsActionConstants from '../../constants/actions/stepsActions';
import { notification } from '../../helperFunctions';
import responseCodes from '../../constants/responseCodes';

export function signUp(formData) {
    let service = authService.signUp;

    if (formData.sign_up_token) {
        service = authService.signUpLinkedIn;
    } else if (formData.continueSignup) {
        service = authService.signUpInvite;
    }

    return (dispatch) => {
        dispatch({ type: SET_SPINNER_VISIBLE });
        dispatch({ type: authActionConstants.SIGN_UP });
        getDeviceFingerprint().then((deviceFingerprint) => {
            service({
                ...formData,
                device_fingerprint: deviceFingerprint
            })
                .then((response) => {
                    baseService
                        .addAuthToken(response.data.payload.access_token)
                        .then(() => {
                            const {response_code} = response.data
                            if (response_code === responseCodes.RECORD_ALREADY_EXISTS ||
                                response_code === responseCodes.AUTH_FAILED) {
                                dispatch({type: authActionConstants.SIGN_UP_ERROR})
                                notification.ref.show({
                                    message: response.data.error,
                                    isError: true
                                });
                            } else {
                                dispatch({
                                    type: authActionConstants.SIGN_UP_SUCCESS,
                                    payload: response.data.payload
                                });
                                if (
                                    response.data.payload.organizations.length > 0
                                ) {
                                    const length =
                                        response.data.payload.organizations.length -
                                        1;
                                    const currentSelectedOrg = {
                                        label: response.data.payload.organizations[
                                            length
                                        ].organization.name,
                                        value: response.data.payload.organizations[
                                            length
                                        ].organization.name,
                                        id: response.data.payload.organizations[
                                            length
                                        ].organization.id,
                                        logo: response.data.payload.organizations[
                                            length
                                        ].organization.logo_image_thumb
                                    };
                                    dispatch({
                                        type: authActionConstants.SET_CURRENT_SELECTED_ORG,
                                        payload: currentSelectedOrg
                                    });
                                }
                                dispatch({
                                    type: stepsActionConstants.GET_STEP_SUCCESS,
                                    payload:
                                        response.data.payload
                                            .current_incomplete_step
                                });
                                Actions.confirmPhone();
                            }
                        });
                })
                .catch((response) => {
                    dispatch({
                        type: authActionConstants.SIGN_UP_ERROR,
                        payload: response.data.error
                    });
                    notification.ref.show({
                        message: response.data.error,
                        isError: true
                    });
                    // if (response.data.response_code === responseCodes.PHONE_NUMBER_REQUIRED) {
                    //     if (response.data.payload.user) {
                    //         const { user: { first_name, last_name, email, workplace, company_position } } = response.data.payload;
                    //         Actions.confirmPhone({
                    //             initialValues: {
                    //                 company_position,
                    //                 follow_company_name: workplace,
                    //                 first_name,
                    //                 last_name,
                    //                 email,
                    //                 password: formData.password
                    //             }
                    //         });
                    //     }
                    // }
                    // if (response.data.response_code === responseCodes.ACCOUNT_IS_NOT_CONFIRMED) {
                    //     Actions.magicLink({
                    //         hideBackLink: true,
                    //         email: formData.email,
                    //         skipInitialSending: true,
                    //         confirmationFlow: true
                    //     });
                    // }
                })
                .finally(() => {
                    dispatch({ type: SET_SPINNER_HIDDEN });
                });
        });
    };
}

export function verifyInviteToken(data) {
    return (dispatch) => {
        dispatch({ type: SET_SPINNER_VISIBLE });
        authService
            .validateUserInviteToken(data)
            .then((response) => {
                dispatch({
                    type: authActionConstants.SIGN_UP_CURRENT_USER_ID,
                    payload: response.data.payload
                });
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => {
                dispatch({ type: SET_SPINNER_HIDDEN });
            });
    };
}

export function verifySsoEnabled(data, successCallback = ()=>{}, errorCallback= ()=>{}) {
    return () => {
      authService
        .verifySsoEnabled(data)
        .then((response) => {
          const { status } = response;
          if (status === responseCodes.SUCCESS) {
            successCallback();
          }
          else {
            errorCallback();
          }
        })
        .catch(() => {
            errorCallback();
        });
    };
  }

export function getInviteData(token) {
    return (dispatch) => {
        dispatch({ type: authActionConstants.GET_USER_INVITE_DATA });
        if (token) {
            dispatch({ type: SET_SPINNER_VISIBLE });
            authService
                .validateInvitationToken(token)
                .then((response) => {
                    dispatch({
                        type: authActionConstants.GET_USER_INVITE_DATA_SUCCESS,
                        payload: response.data.payload
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: authActionConstants.GET_USER_INVITE_DATA_ERROR
                    });
                })
                .finally(() => {
                    dispatch({ type: SET_SPINNER_HIDDEN });
                });
        }
    };
}

export function clearInviteData() {
    return (dispatch) => {
        dispatch({ type: authActionConstants.GET_USER_INVITE_DATA_ERROR });
    };
}

export function updateSignupParams(data) {
    return dispatch => {
        dispatch({ type: SET_SPINNER_VISIBLE });
        authService.updateSignupParams(data).then((response) => {
            dispatch({ type: SET_SPINNER_HIDDEN });
            dispatch({ type: stepsActionConstants.GET_STEP_SUCCESS, payload: response.data.payload.current_incomplete_step });
            Actions.confirmPhone();
        })
    }
}
