import baseService from './baseService.js';
import RouteActions from '../RouteActions.js';
import history from "../RouteHistory";
import Cookies from 'js-cookie';
import AsyncStorage from '@callstack/async-storage';

export function getCurrentStep() {
    return baseService.get('/users/current_step');
}

export function redirectToCurrentStep(step, params = {}) {
    switch (step) {
        case 'signup':
            RouteActions.signUp();
            break;
        case 'invite':
            RouteActions.invitations();
            break;
        case 'create_password':
            RouteActions.resetForgetPassword(params);
            break;
        case 'phone_number':
            RouteActions.confirmPhone();
            break;
        case 'location':
            RouteActions.signUpLocation();
            break;
        case 'request_access':
            RouteActions.signUpRequestAccess();
            break;
        case 'confirm_email':
            RouteActions.magicLink();
            break;
        case 'account_approval':
            RouteActions.waitForAccountApproval();
            break;
        case 'home':
            try {
                AsyncStorage.getItem('before_login').then((value) => {
                    AsyncStorage.removeItem(
                        'before_login'
                    );
                    if (value !== null) {
                        history.push(value);
                    } else {
                        RouteActions.home({params: params});
                    }
                });
            } catch (error) {
                RouteActions.home({params: params});
            }
            break;
        default:
            RouteActions.base();
        // case 'role_identification':
        //     RouteActions.signUpGeneralIfo();
    }
}
