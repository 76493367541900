import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import responseCodes from '../../../constants/responseCodes';
import { loadDefaultSkillOptions } from './defualtSkill';
import * as skillService from '../../../services/skillService';
import CircleBordered from '@material-ui/icons/TripOrigin';
import CircleFilled from '@material-ui/icons/Brightness1';
import Button from '@material-ui/core/Button';

import './StrengthenReferForm.css';

import StrengthQuestionsStepOne from './StrengthQuestionsStepOne.web';
import StrengthQuestionsStepTwo from './StrengthQuestionsStepTwo.web';
import SkipReferUserModal from './SkipReferUserModal';
import ReferUserDetails from './ReferUserDetails';

const StrengthenReferForm = ({
  strengthQuestions,
  handleUploadUserFiles,
  handleUploadUserLinks,
  handleStrengthChange,
  handleSetSkills,
  handleCompleteReferStrenthForm,
  skills,
  orgId,
  diversityQuestion,
  isReferRequestCreated,
  submitReferForm,
  skipStrengthForm,
  hideStrengthQuestions,
  strengthQuestionsSettings,
  stengthSettingsLength,
  formValues,
  progressValues,
  setprogressValues,
  updateStrengthData,
  setreferStep,
  autoSubmitForm,
  setAutoSubmitForm,
  disableSkipButton,
  linkedinSkills,
}) => {
  const { attach_file, attach_url } = strengthQuestionsSettings;
  const [newOptions] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [showSKipModal, setshowSKipModal] = useState(false);
  const [hideToggleButtons, sethideToggleButtons] = useState(false);
  const settingslength = stengthSettingsLength();

  const PROGRESS_VALUES = {
    base: 20,
    candidateRating: 0,
    workWithClient: 0,
    openToNewOppertunities: 0,
    skills: 0,
    filesUploaded: 0,
    userLinksArray: 0,
  };

  const STRENGTH_QUESTION_SETTINGS = {
    candidateRating: 'know_the_person_level',
    workWithClient: 'worked_with_person',
    openToNewOppertunities: 'open_for_new_opportunities_status',
    skills: 'how_good_are_they',
    filesUploadedLinks: 'attach_file',
    filesUploaded: 'attach_file',
    userLinksArray: 'attach_url',
    isDiversityHire: 'diversity_hire',
    licenseAvailable: 'license_available'
  };

  const calculateStrength = () => {
    if (!strengthQuestions) return null;
    Object.entries(strengthQuestions).forEach((question) => {
      if (question[0] === 'userLinksArray') {
        if (strengthQuestionsSettings[STRENGTH_QUESTION_SETTINGS[question[0]]] && question[1].some((e) => e.value)) {
          PROGRESS_VALUES[question[0]] = 80 / settingslength;
        }
      }
      if (question[0] !== 'userLinksArray' && Array.isArray(question[1])) {
        if (strengthQuestionsSettings[STRENGTH_QUESTION_SETTINGS[question[0]]] && question[1].length > 0) {
          PROGRESS_VALUES[question[0]] = 80 / settingslength;
        }
      }
      if (
        !Array.isArray(question[1]) &&
        strengthQuestionsSettings[STRENGTH_QUESTION_SETTINGS[question[0]]] &&
        question[1] !== null &&
        question[1] !== '' &&
        question[1]
      ) {
        PROGRESS_VALUES[question[0]] = 80 / settingslength;
      }
    });
    setprogressValues(Object.values(PROGRESS_VALUES).reduce((a, b) => a + b));
  };

  const handleSkipFirstStepStrengthForm = () => {
    setIsFirstStep(false);
  };

  useEffect(() => {
    if (autoSubmitForm) {
      submitReferForm();
      setAutoSubmitForm(false);
    }
  }, [autoSubmitForm]);

  useEffect(() => {
    if (
      strengthQuestionsSettings.how_good_are_they ||
      strengthQuestionsSettings.know_the_person_level ||
      strengthQuestionsSettings.worked_with_person ||
      strengthQuestionsSettings.open_for_new_opportunities_status ||
      strengthQuestionsSettings.diversity_hire ||
      strengthQuestionsSettings.license_available
    ) {
      setIsFirstStep(true);
    }
  }, []);

  const loadOptions = (searchText, callback) => {
    const parameters = `?organization_id=${orgId}&search_term=${encodeURIComponent(searchText)}`;
    skillService.getSkills(parameters).then((response) => {
      if (response.data.response_code === responseCodes.OK) {
        return callback(response.data.payload.skills);
      }
    });
  };

  const loadSuggestions = debounce(loadOptions, 1000);

  useEffect(() => {
    loadDefaultSkillOptions(orgId).then((response) => {
      setDefaultOptions(response);
    });
  }, []);

  const handleSkipStrengthForm = () => {
    skipStrengthForm();
    setshowSKipModal(false);
  };

  const handleSkipbutton = () => {
    if (hideToggleButtons) {
      setshowSKipModal((previous) => !previous);
    } else if (isFirstStep) {
      setIsFirstStep(false);
    } else {
      setshowSKipModal((previous) => !previous);
    }
  };

  const handleSkipStrengthSteps = () => {
    setreferStep('skip_Strength_Questions');
  };

  const handleHideToggleButtons = () => {
    sethideToggleButtons(true);
  };

  useEffect(() => {
    calculateStrength();
  }, [strengthQuestions, hideStrengthQuestions]);

  return (
    <div className="Strengthen-refer-form-container ">
      <ReferUserDetails
        handleCompleteReferStrenthForm={handleCompleteReferStrenthForm}
        formValues={formValues}
        progressValues={progressValues}
      />
      {!hideToggleButtons && (
        <div className="switch-tabs-wrapper">
          {isFirstStep ? (
            <CircleFilled onClick={() => setIsFirstStep(true)} />
          ) : (
            <CircleBordered onClick={() => setIsFirstStep(true)} />
          )}
          {isFirstStep ? (
            <CircleBordered onClick={() => setIsFirstStep(false)} />
          ) : (
            <CircleFilled onClick={() => setIsFirstStep(false)} />
          )}
        </div>
      )}
      {isFirstStep ? (
        <StrengthQuestionsStepOne
          newOptions={newOptions}
          hideStrengthQuestions={hideStrengthQuestions}
          isReferRequestCreated={isReferRequestCreated}
          strengthQuestions={strengthQuestions}
          defaultOptions={defaultOptions}
          diversityQuestion={diversityQuestion}
          skills={skills}
          loadSuggestions={loadSuggestions}
          handleSetSkills={handleSetSkills}
          handleStrengthChange={handleStrengthChange}
          strengthQuestionsSettings={strengthQuestionsSettings}
          handleSkipStrengthSteps={handleSkipStrengthSteps}
          handleHideToggleButtons={handleHideToggleButtons}
          linkedinSkills={linkedinSkills}
        />
      ) : (
        <StrengthQuestionsStepTwo
          handleUploadUserFiles={handleUploadUserFiles}
          strengthQuestions={strengthQuestions}
          handleUploadUserLinks={handleUploadUserLinks}
          isReferRequestCreated={isReferRequestCreated}
          hideStrengthQuestions={hideStrengthQuestions}
          strengthQuestionsSettings={strengthQuestionsSettings}
          handleHideToggleButtons={handleHideToggleButtons}
        />
      )}

      <div className="actions">
        <Button variant="outlined" className="btn-refer" onClick={handleSkipbutton} disabled={disableSkipButton}>
          Skip
        </Button>
        {!isReferRequestCreated && (
          <Button
            disabled={isFirstStep && !hideToggleButtons ? false : progressValues === 20 ? true : false}
            variant="contained"
            className={
              isFirstStep && !hideToggleButtons
                ? 'btn-apply'
                : progressValues === 20
                ? 'btn-apply-disabled'
                : 'btn-apply'
            }
            onClick={() => (isFirstStep && !hideToggleButtons ? setIsFirstStep(false) : submitReferForm())}
          >
            {isFirstStep && !hideToggleButtons ? 'Next' : 'Save'}
          </Button>
        )}
      </div>
      {showSKipModal && (
        <SkipReferUserModal onSuccess={() => handleSkipStrengthForm()} onCloseModal={() => setshowSKipModal(false)} />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  skills: state.trackerReducer.skills,
  linkedinSkills: state.refersReducer.linkedinSkills,
});

export default connect(mapStateToProps)(StrengthenReferForm);
