import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { reduxForm, Field, change } from 'redux-form';

import { redirectToCurrentStep } from '../../services/stepsService';

import { View, Image, TouchableOpacity, Platform } from 'react-native';
import Actions from '../../RouteActions';
import Spinner from 'react-native-loading-spinner-overlay';

import * as actions from './actions';

import OnboardingBase from '../../components/OnboardingBase/OnboardingBase';
import Text from '../../components/Text/Text';
import SignupButton from '../../components/SignupButton/SignupButton.js';
import SignUpBackButton from '../../components/SignUpBackButton/SignUpBackButton';
import AuthLayout from '../../layouts/AuthLayout/AuthLayout';
import FormInput from '../../components/FormInput/FormInput';
import { notification } from '../../helperFunctions';
import { getDefaultSettings } from '../Refer/actions';

import styles from './styles';

import GreenMailIcon from '../../assets/svg/green-mail-icon.svg';
import history from '../../RouteHistory';

class MagicLink extends Component {
    componentDidMount() {
        const {
            email,
            requestMagicLink,
            requestConfirmationLink,
            skipInitialSending,
            confirmationFlow,
            resetPassword,
            requestResetPassword,
            referSignup,
            currentUser,
            step,
            organization_id,
            getOrganizationSettings,
            member_approvel_required
        } = this.props;
        if (step === 'home') {
            redirectToCurrentStep('home');
        }
        if(step === 'process completed'){
            history.push('/request_access')
        }
        if (step === 'confirm_email' && currentUser.memberships.length > 0) {
            redirectToCurrentStep('home');
        }
        if (email && !skipInitialSending) {
            if (confirmationFlow) {
                requestConfirmationLink(email);
            } else if (resetPassword) {
                requestResetPassword(email);
            } else if (!referSignup) {
                requestMagicLink(email);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const { setSpinnerVisible, setSpinnerHidden, magicLinkResponse } =
            nextProps;

        const resp_code = magicLinkResponse.response_code;
        if (resp_code == undefined) {
            // TODO: Spinner was not getting reset on try again click, so temporarily removed spinner show
            // setSpinnerVisible();
        } else {
            setSpinnerHidden();
        }
    }

    resendEmail = () => {
        let { email } = this.props;
        const {
            requestMagicLink,
            requestConfirmationLink,
            confirmationFlow,
            currentUser
        } = this.props;

        email = email || currentUser.email;

        if (email) {
            if (confirmationFlow || !currentUser.confirmed_at) {
                requestConfirmationLink(email);
            } else {
                requestMagicLink(email);
            }
        }
    };

    submitForm = (e) => {
        e.preventDefault();
        const { formValues, membership_approval_required } = this.props;
        if (!formValues.verfication_code.trim().length) {
            notification.ref.show({
                message: 'Please fill confirmation code field',
                isError: true
            });
        } else {
            this.props.submitConfirmationCode(
                formValues.verfication_code,
                membership_approval_required
            );
        }
    };

    render() {
        const {
            hideBackLink,
            magicLinkResponse,
            confirmationFlow,
            submitConfirmationCode,
            referSignup,
            hideCode,
            currentUser,
            organization_id,
            formValues,
            onGoBackLocation
        } = this.props;
        let resp_code = magicLinkResponse.response_code;
        let { email } = this.props;
        if (!email) {
            email = currentUser.email;
            resp_code = 0;
        }

        return (
            <AuthLayout>
                <View style={styles.container}>
                    <View style={styles.formWrapper}>
                        <View style={styles.header}>
                            <View style={styles.headerImage}>
                                <img src={GreenMailIcon} />
                            </View>
                            <Text style={styles.heading}>Check your inbox</Text>
                            <Text style={styles.secondaryText}>
                                We've sent you a confirmation email!
                            </Text>
                        </View>
                        <View style={styles.fieldContainer}>
                            <Field
                                component={FormInput}
                                name="verfication_code"
                                placeholder="Verification Code"
                                wrapperStyle={[styles.fullWidth]}
                                inputRef={(ref) =>
                                    (this.recentPositionRef = ref)
                                }
                                returnKeyType="next"
                                labelStyle={styles.labelStyle}
                                inputStyle={styles.inputStyle}
                            />
                        </View>
                        <View style={styles.buttonWrapper}>
                            <View>
                                <SignupButton
                                    onClick={(e) => {
                                        this.submitForm(e);
                                    }}
                                    disabled={!formValues.verfication_code}
                                >
                                    Confirm
                                </SignupButton>
                                <View style={styles.backButtonWrapper}>
                                    <SignUpBackButton
                                        onClick={onGoBackLocation}
                                    >
                                        Back
                                    </SignUpBackButton>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.bottomWrapper}>
                        <Text style={styles.bottomText}>
                            Didn't receive?{' '}
                            <TouchableOpacity
                                style={styles.bottomTextLink}
                                onPress={this.resendEmail}
                            >
                                <Text>Send again</Text>
                            </TouchableOpacity>
                        </Text>
                    </View>
                </View>
            </AuthLayout>
        );
    }
}

MagicLink = reduxForm({ form: 'magicLinkForm' })(MagicLink);

const mapStateToProps = (state) => {
    return {
        formValues:
            state.form.magicLinkForm && state.form.magicLinkForm.values
                ? state.form.magicLinkForm.values
                : {},
        referredJobData: state.refersReducer.referredJobData,
        magicLinkResponse: state.authReducer.magicLink,
        currentUser: state.authReducer.currentUser,
        step: state.stepsReducer.step,
        organization_id:
            (state.organizationsReducer.organizationId &&
                state.organizationsReducer.organizationId.organization_id) ||
            (state.organizationsReducer &&
                state.organizationsReducer.organizationDetails &&
                state.organizationsReducer.organizationDetails.id),
        membership_approval_required:
            state.settingsReducer &&
            state.settingsReducer.defaultValues &&
            state.settingsReducer.defaultValues.membership_approval_required
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        requestMagicLink: (email) => {
            dispatch(actions.requestMagicLink(email));
        },
        requestResetPassword: (email) => {
            dispatch(actions.requestResetPassword(email));
        },
        requestConfirmationLink: (email) => {
            dispatch(actions.requestConfirmationLink(email));
        },
        setSpinnerVisible: () => {
            dispatch(actions.setSpinnerVisible());
        },
        setSpinnerHidden: () => {
            dispatch(actions.setSpinnerHidden());
        },
        submitConfirmationCode: (
            verfication_code,
            membership_approval_required
        ) => {
            dispatch(
                actions.submitConfirmationCode(
                    verfication_code,
                    membership_approval_required
                )
            );
        },
        getOrganizationSettings: (orgID) => {
            dispatch(getDefaultSettings(orgID));
        },
        onGoBackLocation: () => {
            dispatch(
                actions.updateStepsOnBackButton({
                    current: 'confirm_email',
                    new: 'location'
                })
            );
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MagicLink);
