// Library Imports
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withGetScreen } from 'react-native-getscreen';
import { View, Image, TouchableOpacity, Linking } from 'react-native';
import { isEmpty } from 'lodash-es';

// Icons/Assets
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import WorkOutline from '@material-ui/icons/WorkOutline';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import shareIcon from '../../../../assets/img/share_icon.png';
import shareIconWhite from '../../../../assets/img/share_icon_white.png';
import defaultOrgAvatar from '../../../../assets/img/default_org_avatar.svg';
import winnerCupSvg from '../../../../assets/svg/winner-cup.svg';
import defaultBannerImage from '../../../../assets/svg/employer-profile-banner.svg';

// Actions
import {
  openBrowseFiltersDrawer,
  openReferFormDrawer,
  openJobApplyDrawer,
  openDeclineJobDrawer,
} from '../../../../DrawerActions';
import Actions from '../../../../RouteActions';
import { getUserJobReferData, getCandidateData, getSocialShareLink } from './actions';
import { getDetails } from '../../../../globalActions/browserActions';
import * as refersActions from '../../../../../src/globalActions/refersActions';

// Components
import Text from '../../../../components/Text/Text';
import ListElementButton from '../../../../components/ListElementButton/ListElementButton';
import OpeningUnavailable from './OpeningUnavailable';
import ReferralRewardsRow from './ReferralRewardsRow';
import ShareMenu from './ShareMenu';

// Services/Helpers
import {
  locationObjToStr,
  getDateTimeFromNow,
  isDateValid,
  getLinkedInShareLink,
  getFacebookShareLink,
  getWhatsAppShareLink,
  getTwitterShareLink,
} from '../../../../helperFunctions';

// Styles
import { common as commonStyles, jobListElement } from '../../../../generalStyles';
import responsiveStyles from '../../../../responsiveStyles';
import styles from './styles';
import AnimatedBorder from '../../../../wrappers/AnimatedBorder/AnimatedBorder.web';
import OrganizationDescription from '../../../../components/OrganizationDescription/OrganizationDescription.web';
import DashboardBase from '../../../../components/DashboardBase/DashboardBase';
import { ScrollView } from 'react-native-gesture-handler';
import RouteHistory from '../../../../RouteHistory';
import useWindowSize from '../../../../hooks/useWindowResize';
import { getOrganizationDetailsByGeneralSettingId } from '../../../../services/organizationsService';

const mainContainer = {
  backgroundColor: 'white',
  marginBottom: 16,
};
const rewardContainer = {
  backgroundColor: 'white',
  marginBottom: 0,
  paddingTop: '8px',
  paddingBottom: '8px',
  borderRadius: '9px',
};

const ownStyles = {
  image: {
    width: 60,
    height: 60,
    marginRight: 12,
    borderRadius: 5,
    borderRadius: 1,
    borderStyle: 'solid',
    borderColor: '#D1D8DC',
  },
  links: {
    color: '#00cc6e',
    textDecorationLine: 'underline',
    fontWeight: 'bold',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 26,
    fontWeight: '700',
    marginTop: 20,
    color: '#18332F',
    paddingBottom: 8,
  },
  titleImage: {
    width: 20,
    height: 20,
    marginLeft: 12,
  },
  titleMobile: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '26px',
    display: 'flex',
    color: '#18332F',
  },
  header: {
    fontSize: 20,
    paddingBottom: 8,
    fontWeight: 'bold',
    color: '#959595',
  },
  titleCont: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    paddingRight: 5,
  },
  titleWeb: {
    fontSize: 25,
    fontWeight: '700',
    lineHeight: '26px',
    display: 'flex',
    color: '#18332F',
    whiteSpace: 'nowrap',
    overflow: ' hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  description: {
    fontSize: 14,
    lineHeight: 16,
  },
  innerHTML: {
    fontSize: 14,
    lineHeight: 16,
    marginTop: 24,
  },
  descriptionMobile: {
    fontSize: 13,
    lineHeight: 20,
    opacity: 0.6,
  },
  salary: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  salaryMobile: {
    fontSize: 25,
    fontWeight: 'bold',
  },
  tagsWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: 8,
  },
  tag: {
    marginBottom: 8,
    alignSelf: 'stretch',
    width: 'auto',
    paddingHorizontal: 8,
  },
  skillTag: {
    width: 74,
    borderRadius: 3,
    paddingVertical: 5,
    marginRight: 8,
    backgroundColor: '#EBF0F3',
  },
  skillTagText: {
    fontSize: 12,
    color: '#444444',
    textAlign: 'center',
  },
  buttonsWrapper: {
    flexDirection: 'row',
    marginVertical: 5,
  },
  detailsWrapper: {
    padding: 18,
  },
  map: {
    width: '100%',
    height: 300,
    marginTop: 10,
  },
  mapMobile: {
    width: '100%',
    height: 200,
    marginTop: 10,
  },
  locationText: {
    fontSize: 16,
  },
  full_address: {
    width: '15%',
    marginTop: 10,
  },
  full_addressMobile: {
    width: '50%',
    marginTop: 10,
  },
  jobButtonGroup: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '2%',
  },
  rewardDescription: {
    padding: 8,
    marginTop: 8,
  },
  subHeading: {
    marginBottom: 4,
    color: '#999999',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: '16px',
  },
  SalaryRange: {
    marginTop: 4,
  },
  textBold: {
    fontWeight: 600,
  },
  referralRewardsSection: {
    marginBottom: 16,
  },
  goBackButton: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 20,
    paddingHorizontal: 10,
    paddingBottom: 5,
  },
  goBackText: {
    fontSize: 19,
    paddingLeft: 10,
    color: '#444444',
  },
  mobileWrapper: {
    paddingHorizontal: 10,
    paddingVertical: 40,
  },
  horizontalRuler: {
    color: '#f2f2f2',
    backgroundColor: '#f2f2f2',
    marginBottom: '2rem',
    border: 'none',
    height: 2,
    width: '100%',
  },
  rewardIcon: {
    marginRight: 10,
  },
};

const referButtonStyle = {
  borderRadius: 3,
  borderColor: '#0FBC71',
  height: 40,
  width: 100,
  marginLeft: 10,
};
const alreadyAppliedStyle = {
  borderRadius: 3,
  height: 40,
  width: 100,
  marginRight: 5,
  backgroundColor: '#E9E9E9',
};
const applyButtonStyle = {
  borderRadius: 3,
  borderColor: '#0fbc71',
  height: 40,
  width: 100,
  marginLeft: 5,
  backgroundColor: '#0FBC71',
  boxShadow: '0px 2px 7px rgba(116, 113, 113, 0.317499)',
};

const OpeningDetail = (props) => {
  const {
    firstJob,
    details,
    getDetails,
    match,
    currentUser,
    getCandidateData,
    currentSelectedOrgName,
    candidateData,
    getUserJobReferData,
    openJobApplyForm,
    openJobReferForm,
    getUsers,
    job,
    isMobile: isMobileFunc,
    orgdetail,
    orgDetails,
    currentSelectedOrgId,
    userOrganizations,
    getSocialShareLink,
  } = props;
  const isMobile = isMobileFunc();
  const size = useWindowSize();
  const calledGetDetailsRef = useRef(null);
  const calledGetCandidateJobDataRef = useRef(null);
  const currentUserIdRef = useRef(null);
  const calledGetUsersRef = useRef(null);
  const { openingId, openingType } = match.params;
  const [expanded, setExpanded] = useState(false);
  const [dynamicOrgData, setDynamicOrgData] = useState(null);

  useEffect(() => {
    const id = match.params.id || (firstJob && firstJob.id);
    if (!!openingId) {
      return getDetails(openingId, !!currentSelectedOrgId ? currentSelectedOrgId : null);
    }
    if (!id) return;
    if (calledGetDetailsRef.current !== id) {
      calledGetDetailsRef.current = id;
      getDetails(id, details && details.organization ? details.organization.id : null, (firstJob && firstJob.id) || id);
    }

    return () => {
      setExpanded(false);
    };
  }, [firstJob, match.params]);

  useEffect(() => {
    const id = details && details.id;
    if (id && (calledGetCandidateJobDataRef.current !== id || currentUserIdRef.current !== currentUser.id)) {
      calledGetCandidateJobDataRef.current = id;
      currentUserIdRef.current = currentUser.id;
      const params = {
        userId: currentUser.id,
        jobId: id,
        organization_id: currentSelectedOrgId || details.organization_id,
      };
      getCandidateData(params);
      getUserJobReferData(params);
    }
  }, [currentUser, details, match.params]);

  useEffect(() => {
    if (details && details.id && calledGetUsersRef.current !== details.id) {
      calledGetUsersRef.current = details.id;
      getUsers(details.id, details.uuid);
    }
  }, [details]);

  const getDynamicOrgData = async (dynamicOrg) => {
    try {
      const {
        data: {
          payload: { organization },
        },
      } = await getOrganizationDetailsByGeneralSettingId(dynamicOrg);
      console.log('org', organization);
      return organization;
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (sessionStorage.getItem('dynamicOrg')) {
        const res = await getDynamicOrgData(sessionStorage.getItem('dynamicOrg'));
        setDynamicOrgData(res);
      }
    };

    fetchData();
  }, []);

  const isUserPartOfJobOrg = useMemo(() => {
    if (userOrganizations && userOrganizations.length > 0 && details) {
      return !!userOrganizations.find(
        (userOrg) => userOrg.organization.id === details.organization_id && userOrg.status_name === 'active'
      );
    }
    return false;
  }, [userOrganizations, details]);

  const renderActionButtons = useMemo(() => {
    if (!details || !details.id) return null;
    const referInitialValues = details.id
      ? {
          job_id: {
            id: details.id,
            title: details.title,
          },
          organization_id: {
            id: details.organization.id,
            name: details.organization.name,
            logo_image_url: details.org_logo_image,
          },
        }
      : {};
    const applyInitialValues = details.id
      ? {
          job_id: {
            id: details.id,
            title: details.title,
            city: details.organization_location ? details.organization_location.city : '',
            state: details.organization_location ? details.organization_location.state : '',
            job_type_name: details.job_type ? details.job_type.name : '',
            logo_image_url: details.org_logo_image,
            organization_name: details.organization.name,
            organization_id: details.organization.id,
            published_at: details.created_at,
            remote_type: details.job_remote_work_type ? details.job_remote_work_type.name : '',
            salary: details.salary,
            location: details.location,
          },
          links_json: currentUser.links_json ? JSON.parse(currentUser.links_json) : [{ type: 'ln' }],
        }
      : {};
    const already_applied =
      details && details.candidates && details.candidates.filter((x) => x.user_id === currentUser.id).length > 0;
    const applied = candidateData && candidateData.applied;
    const declined = candidateData && candidateData.declined;

    return (
      <View>
        <View style={[ownStyles.buttonsWrapper, ownStyles.jobButtonGroup]}>
          {((!applied && !already_applied) || declined) && (
            <ListElementButton
              textStyle={{ color: 'white' }}
              style={applyButtonStyle}
              onPress={() => openJobApplyForm(applyInitialValues)}
            >
              Apply
            </ListElementButton>
          )}
          {(applied || already_applied) && !declined && (
            <ListElementButton textStyle={{ color: '#B6B6B6' }} style={alreadyAppliedStyle} disabled={true}>
              Applied
            </ListElementButton>
          )}
          <ListElementButton
            textStyle={{ color: !details.user_permitted ? '#b6b6b6' : '#0FBC71' }}
            style={
              !details.user_permitted
                ? { ...alreadyAppliedStyle, marginLeft: '5px', marginRight: '0px' }
                : referButtonStyle
            }
            endIcon={applied && !details.user_permitted ? shareIconWhite : shareIcon}
            disabled={!details.user_permitted}
            userPermitted={details.user_permitted}
            onPress={() => {
              if (isEmpty(currentUser)) {
                dynamicOrgData && dynamicOrgData.org_referral_link
                  ? Linking.openURL(`${dynamicOrgData.org_referral_link}?refer_for=${details.id}`)
                  : Linking.openURL(`${orgDetails.org_referral_link}?refer_for=${details.id}`);
              } else {
                openJobReferForm(referInitialValues);
              }
            }}
          >
            Refer
          </ListElementButton>
          <ShareMenu
            jobId={details.id}
            currentUser={currentUser}
            getSocialShareLink={(type) => {
              if (type === 'twitter') {
                getSocialShareLink(type, details.id, details.title, currentSelectedOrgName);
              } else {
                getSocialShareLink(type, details.id);
              }
            }}
          />
        </View>
      </View>
    );
  }, [details, candidateData]);

  const renderBasicInfo = useMemo(() => {
    if (!details) return null;
    let industries = [];
    if (details.industries && details.industries.length) {
      industries = details.industries.filter((item) => Boolean((item.name || '').trim()));
    }
    if (
      (!details.job_education || !details.job_education.name) &&
      (!details.job_experience || !details.job_experience.name) &&
      !industries.length
    )
      return null;

    return (
      <View style={[mainContainer]}>
        <View
          style={[
            ownStyles.detailsWrapper,
            commonStyles.row,
            commonStyles.flexWrap,
            commonStyles.justifyContentSpaceBetween,
            { paddingBottom: 0 },
          ]}
        >
          {Boolean(details.job_education) && (
            <View style={styles.cell}>
              <Text style={ownStyles.header}>Required Education</Text>
              <Text style={ownStyles.description}>{details.job_education.name}</Text>
            </View>
          )}

          {Boolean(details.job_experience) && (
            <View style={styles.cell}>
              <Text style={ownStyles.header}>Experience Level</Text>
              <Text style={ownStyles.description}>{details.job_experience.name}</Text>
            </View>
          )}

          {industries.length > 0 && (
            <View style={styles.cell}>
              <Text style={ownStyles.header}>Industries</Text>
              <View style={ownStyles.tagsWrapper}>
                {industries.map((item) => (
                  <View style={[jobListElement.tag, ownStyles.tag]} key={item.id}>
                    <Text style={[jobListElement.tagText, ownStyles.skillTagText]}>{item.name}</Text>
                  </View>
                ))}
              </View>
            </View>
          )}

          {Boolean(details.function) && (
            <View style={styles.cell}>
              <Text style={ownStyles.header}>Opening Function</Text>
              <Text style={ownStyles.description}>{details.function.name}</Text>
            </View>
          )}

          {Boolean(details.external_job_id) && (
            <View style={styles.cell}>
              <Text style={ownStyles.header}>External Job ID</Text>
              <Text style={ownStyles.description}>{details.external_job_id}</Text>
            </View>
          )}
        </View>
      </View>
    );
  }, [details]);

  const renderSkills = useMemo(() => {
    const skills =
      (details &&
        details.skills &&
        details.skills.length &&
        details.skills.filter((s) => Boolean((s.name || '').trim()))) ||
      [];
    if (!skills.length) return null;
    return (
      <View style={[mainContainer]}>
        <View style={[ownStyles.detailsWrapper]}>
          <View>
            <Text style={ownStyles.header}>Skills</Text>
            <View style={ownStyles.tagsWrapper}>
              {skills.map((skill) => (
                <View style={[jobListElement.tag, ownStyles.tag]} key={skill.id}>
                  <Text style={[jobListElement.tagText, ownStyles.skillTagText]}>{skill.name}</Text>
                </View>
              ))}
            </View>
          </View>
        </View>
      </View>
    );
  }, [details]);

  const renderDescription = useMemo(() => {
    if (!details || !details.description) return null;
    return (
      <View style={[mainContainer]}>
        <View style={[ownStyles.detailsWrapper]}>
          <View>
            <Text style={ownStyles.header}>Description</Text>
            <Text
              id="ql-editor"
              dangerouslySetInnerHTML={{
                __html: details.description,
              }}
            ></Text>
            <OrganizationDescription />
          </View>
        </View>
      </View>
    );
  }, [details]);

  const renderReferralRewards = useMemo(() => {
    if (!details || !details.calculated_reward_templates) return null;

    const {
      experiential_reward = '',
      monetary_reward = 0,
      description = '',
      notes = '',
    } = details.calculated_reward_templates;
    const { internal_tcs, external_tcs } = details;

    const termsConditions = isUserPartOfJobOrg ? internal_tcs : external_tcs;

    if ((experiential_reward.length || monetary_reward) > 0 || termsConditions) {
      return (
        <View style={[mainContainer]}>
          <View style={[ownStyles.detailsWrapper]}>
            <View>
              <Text style={ownStyles.header}>
                <Image style={jobListElement.winnerIcon} source={winnerCupSvg} />
                Referral Rewards
              </Text>

              {(experiential_reward.length || monetary_reward) > 0 && (
                <div style={ownStyles.rewardDescription}>
                  <div className="main-div">
                    {details.reward_templates.map((template, index) => (
                      <div key={index}>
                        <div>
                          <ReferralRewardsRow
                            key={index}
                            installments={template.reward_template_installments_attributes}
                            templateDescription={template.description}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {(experiential_reward.length || monetary_reward) > 0 && !!termsConditions && (
                <hr style={ownStyles.horizontalRuler} />
              )}
              {!!termsConditions && (
                <View style={(ownStyles.rewardDescription, { zIndex: -1 })}>
                  <Text style={{ fontSize: 20, color: '#959595', fontWeight: 'bold' }}>Terms and Conditions</Text>
                  <Text
                    id="ql-editor"
                    style={{
                      marginTop: 8,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: termsConditions ? termsConditions.replace(/<img/g, '<img height="12" width="12"') : '',
                    }}
                  />
                </View>
              )}
              {notes.length > 0 && (
                <View style={ownStyles.rewardDescription}>
                  <Text
                    style={{
                      fontSize: 14,
                      color: '#444444',
                      marginTop: i === 0 ? 8 : 16,
                    }}
                  >
                    {`NOTE: ${notes}`}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </View>
      );
    }
    return null;
  }, [details, isUserPartOfJobOrg, expanded]);
  const renderNonLoggedInReferralRewards = useMemo(() => {
    return (
      <AnimatedBorder style={ownStyles.referralRewardsSection}>
        <View style={[rewardContainer]}>
          <View style={[ownStyles.detailsWrapper]}>
            <Text style={ownStyles.header}>Referral Rewards</Text>
            <View style={jobListElement.referralRewardsWrapper}>
              <View style={ownStyles.rewardIcon}>
                <Image style={jobListElement.winnerIcon} source={winnerCupSvg} resizeMode="contain" />
              </View>

              <Text style={ownStyles.description}>
                {!!details && !!details.organization && details.organization.name} offers rewards for referral hires.{' '}
                <TouchableOpacity onPress={() => Actions.login()}>
                  <Text style={ownStyles.links}>Log in</Text>
                </TouchableOpacity>{' '}
                or{' '}
                <TouchableOpacity onPress={() => Actions.signUp()}>
                  <Text style={ownStyles.links}>Sign up</Text>
                </TouchableOpacity>{' '}
                to learn more!
              </Text>
            </View>
          </View>
        </View>
      </AnimatedBorder>
    );
  }, [details]);

  const renderCompany = useMemo(() => {
    if (!details || !details.organization) return null;
    const image = details.org_logo_image ? { uri: details.org_logo_image } : defaultOrgAvatar;

    return (
      <div style={{ position: 'relative', zIndex: -10 }}>
        <View style={[mainContainer]}>
          <View style={[ownStyles.detailsWrapper]}>
            <View>
              <Text style={ownStyles.header}>About the company</Text>
              <View
                style={[
                  commonStyles.row,
                  {
                    marginTop: 12,
                    marginBottom: 19,
                    ...jobListElement.elementWrapperTopAligned,
                  },
                ]}
              >
                <TouchableOpacity
                  style={{ marginRight: 8 }}
                  onPress={() => {
                    Actions.goEmployerProfile({
                      employerProfileLink: details.employer_profile_link,
                    });
                  }}
                >
                  <Image
                    style={{
                      width: 52,
                      height: 52,
                      borderRadius: 5,
                      overflow: 'hidden',
                    }}
                    source={image}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
                <View style={jobListElement.infoWrapper}>
                  <TouchableOpacity
                    style={commonStyles.linkWrapper}
                    onPress={() => {
                      Actions.goEmployerProfile({
                        employerProfileLink: details.employer_profile_link,
                      });
                    }}
                  >
                    <Text
                      style={{
                        color: '#18332F',
                        paddingHorizontal: 0,
                        marginBottom: 4,
                        fontWeight: '700',
                        fontSize: 26,
                        lineHeight: '26px',
                      }}
                    >
                      {details.organization.name}
                    </Text>
                  </TouchableOpacity>
                  {Boolean(details.organization_location) && (
                    <View style={commonStyles.linkWrapper}>
                      <Text style={{ fontSize: 14, lineHeight: '21px' }} numberOfLines={1}>
                        <span style={{ color: '#999999', fontSize: 14 }}>
                          {locationObjToStr(details.organization_location)}
                        </span>
                      </Text>
                    </View>
                  )}
                </View>
              </View>
              {Boolean(details.organization.overview) && (
                <Text
                  id="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: details.organization.overview || '',
                  }}
                ></Text>
              )}
            </View>
          </View>
        </View>
      </div>
    );
  }, [details]);

  if (!details || !details.id) {
    return null;
  }

  let diffStr = '';
  if (isDateValid(details.created_at)) {
    diffStr = getDateTimeFromNow(details.created_at);
  }

  const image = details.org_logo_image ? { uri: details.org_logo_image } : defaultOrgAvatar;
  const bannerImage = details.org_cover_picture_image ? { uri: details.org_cover_picture_image } : defaultBannerImage;
  const profile_image_style = Boolean(details.is_salary_range_public && details.min_salary && details.max_salary)
    ? { width: 100, height: 100, marginRight: 0 }
    : { width: 82, height: 82, marginRight: 0 };

  let formattedOpeningLocation = locationObjToStr(details.location);
  const RenderJobDetails = () => {
    return (
      <View style={[responsiveStyles.justifyCenter, ownStyles.container, isMobile && ownStyles.mobileWrapper]}>
        {job == false && (
          <View>
            <Image source={bannerImage} style={{ height: 184 }} resizeMode="cover" />
          </View>
        )}
        <View style={[mainContainer]}>
          <View
            style={
              job == false
                ? [
                    {
                      paddingLeft: 18,
                      paddingTop: 40,
                      ...jobListElement.elementWrapperTopAligned,
                    },
                  ]
                : [
                    commonStyles.row,
                    {
                      marginTop: 20,
                      paddingLeft: 18,
                      ...jobListElement.elementWrapperTopAligned,
                    },
                  ]
            }
          >
            <TouchableOpacity
              style={
                job == true
                  ? { marginRight: 18 }
                  : [
                      jobListElement.imageWrapper,
                      {
                        width: 82,
                        height: 82,
                        position: 'absolute',
                        top: -41,
                        backgroundColor: 'white',
                      },
                    ]
              }
              onPress={() => {
                Actions.goEmployerProfile({
                  employerProfileLink: details.employer_profile_link,
                });
              }}
            >
              <Image style={[ownStyles.image, profile_image_style]} source={image} resizeMode="contain" />
            </TouchableOpacity>
            <View style={{ paddingTop: size.width <= 468 ? 58 : 5, flex: 1 }}>
              <View style={ownStyles.titleCont}>
                <Text style={isMobile ? ownStyles.titleMobile : ownStyles.titleWeb}>{details.name}</Text>
                {job == false ? (
                  <PeopleAltOutlinedIcon style={ownStyles.titleImage} />
                ) : (
                  <WorkOutline style={{ marginLeft: 12 }} />
                )}
              </View>
              {(Boolean(details.organization.name) || Boolean(details.organization_location)) && (
                <>
                  {Boolean(details.organization.name) && (
                    <TouchableOpacity
                      style={commonStyles.linkWrapper}
                      onPress={() => {
                        Actions.goEmployerProfile({
                          employerProfileLink: details.employer_profile_link,
                        });
                      }}
                    >
                      <Text
                        style={job == true ? { ...ownStyles.subHeading, ...ownStyles.textBold } : ownStyles.subHeading}
                        numberOfLines={1}
                      >
                        {details.organization.name}
                      </Text>
                    </TouchableOpacity>
                  )}
                  <View style={commonStyles.linkWrapper}>
                    <Text numberOfLines={1}>
                      <span style={ownStyles.subHeading}>{formattedOpeningLocation}</span>
                      {job && formattedOpeningLocation && ' • '}
                      {job && diffStr}
                    </Text>
                  </View>
                </>
              )}
              {Boolean(details.is_salary_range_public && details.min_salary && details.max_salary) && (
                <View style={(commonStyles.linkWrapper, { paddingTop: 4 })}>
                  <Text
                    style={
                      job ? [ownStyles.subHeading, ownStyles.textBold, ownStyles.SalaryRange] : ownStyles.subHeading
                    }
                    numberOfLines={1}
                  >
                    <Text>
                      {details.currency}
                      <Text>{details.min_salary}</Text>
                    </Text>
                    {' \u2013 '}
                    <Text>
                      {details.currency}
                      <Text>{details.max_salary}</Text>
                    </Text>
                  </Text>
                </View>
              )}
            </View>
          </View>

          {Boolean(
            details.job_type || details.job_remote_work_type || details.job_experience || details.job_urgency_type
          ) && (
            <View style={[jobListElement.tagsWrapper, { marginTop: 20 }]}>
              {Boolean(details.job_type && details.job_type.name) && (
                <View style={jobListElement.tag}>
                  <Text style={jobListElement.tagText}>{details.job_type.name}</Text>
                </View>
              )}
              {Boolean(details.job_experience && details.job_experience.name) && (
                <View style={jobListElement.tag}>
                  <Text style={jobListElement.tagText}>{details.job_experience.name}</Text>
                </View>
              )}
              {Boolean(details.job_remote_work_type && details.job_remote_work_type.name) && (
                <View style={jobListElement.tag}>
                  <Text style={jobListElement.tagText}>{details.job_remote_work_type.name}</Text>
                </View>
              )}
              {Boolean(details.job_urgency_type && details.job_urgency_type.name == 'Urgent') && (
                <View style={jobListElement.tag}>
                  <Text style={{ ...jobListElement.tagText, display: 'flex' }}>
                    <TimerOutlinedIcon style={{ fontSize: 12, marginRight: 3 }} />
                    {details.job_urgency_type.name}
                  </Text>
                </View>
              )}
            </View>
          )}
          {renderActionButtons}
        </View>
        {renderSkills}
        {renderDescription}
        {renderBasicInfo}
        {currentUser && Object.keys(currentUser).length > 0
          ? renderReferralRewards
          : orgdetail && Object.keys(orgdetail).length > 0 && orgdetail.show_public_rewards
          ? renderReferralRewards
          : renderNonLoggedInReferralRewards}

        {renderCompany}
      </View>
    );
  };

  if (details && details.published === false) {
    return (
      <View style={[responsiveStyles.justifyCenter, ownStyles.container]}>
        <OpeningUnavailable />
      </View>
    );
  } else {
    if (isMobile) {
      return (
        <DashboardBase isProtectedRoute={false} title={!!openingType ? openingType : 'Opening Details'}>
          <ScrollView>
            <TouchableOpacity onPress={() => RouteHistory.goBack()}>
              <View style={ownStyles.goBackButton}>
                <ArrowBackIcon />
                <Text style={ownStyles.goBackText}>Back</Text>
              </View>
            </TouchableOpacity>
            <RenderJobDetails />
          </ScrollView>
        </DashboardBase>
      );
    } else {
      return <RenderJobDetails />;
    }
  }
};

const mapStateToProps = (state) => {
  return {
    candidateData: state.browseReducer.candidateData,
    details: state.browseReducer.jobDetails,
    detailsLoading: state.browseReducer.detailsLoading,
    referredJobData: state.refersReducer.referredJobData,
    currentUser: state.authReducer.currentUser,
    queryString: state.suggestionsReducer.queryString,
    orgId: state.organizationsReducer.organizationDetails.id,
    orgDetails: state.organizationsReducer.organizationDetails,
    orgdetail: state.browseReducer.employer,
    currentSelectedOrgId: state.authReducer.currentSelectedOrg.id,
    currentSelectedOrgName: state.authReducer.currentSelectedOrg.name,
    userOrganizations: state.organizationsReducer.userOrganizations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openFilterDrawer: () => {
      dispatch(openBrowseFiltersDrawer());
    },
    getDetails: (id, organizationId, fallbackId) => {
      dispatch(getDetails('job', id, organizationId, fallbackId));
    },
    openJobReferForm: (params) => {
      dispatch(openReferFormDrawer(params));
    },
    openJobApplyForm: (params) => {
      dispatch(openJobApplyDrawer(params));
    },
    openDeclineJobDrawer: (params) => {
      dispatch(openDeclineJobDrawer(params));
    },
    getUserJobReferData: (params) => {
      dispatch(getUserJobReferData(params));
    },
    getCandidateData: (params) => {
      dispatch(getCandidateData(params));
    },
    getSocialShareLink: async (type, jobId, title, organizationName) => {
      const jobUrl = await dispatch(getSocialShareLink(jobId));

      if (jobUrl) {
        let socialUrl;
        switch (type) {
          case 'linkedin':
            socialUrl = getLinkedInShareLink(jobUrl);
            break;
          case 'facebook':
            socialUrl = getFacebookShareLink(jobUrl);
            break;
          case 'whatsapp':
            socialUrl = getWhatsAppShareLink(jobUrl);
            break;
          case 'twitter':
            const text = `Checkout ${title} opening at ${organizationName}`;
            socialUrl = getTwitterShareLink(jobUrl, text);
            break;
          default:
            throw new Error('Invalid social media type');
        }
        window.open(socialUrl, '_blank');
      }
    },
    getUsers: (job_id, job_uuid) => {
      return dispatch(refersActions.getReferUsers(job_id, job_uuid));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withGetScreen(OpeningDetail)));
